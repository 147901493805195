import Logo from "./PageComponents/Logo";
import AboutPageBackground from "../../assets/AboutPageBackground.png";
import useWindowSize from "../../utilities/useWindowSize";
import Therapists from "../../data/Therapists";
import { useState, useEffect } from "react";
import "./About.css";

/* 
  Pictures are cut off on mobile for about page and contact pages
 */

function About() {
  const [isMobile, setMobile] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    setMobile(windowSize.width > 900 ? false : true);
  }, [windowSize]);

  let background;
  let padding;
  let imageStyle;
  if (isMobile) {
    background = AboutPageBackground;
    padding = "37px 22px";
    imageStyle = { height: "auto", maxWidth: "150px", overflow: "hidden" };
  } else {
    background = AboutPageBackground;
    padding = "25px 50px";
    imageStyle = { height: "auto", width: "359px", overflow: "hidden" };
  }

  const therapists = Therapists.map((t, i) => {
    return (
      <li key={`therapist-${i}`}>
        <div
          className="therapist rowItem"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: `${
              t.Title[0] === "Marissa Kaufman" ? "0px auto" : "50px auto"
            }`,
          }}
        >
          <div
            className="therapist text"
            style={{ width: "60%", marginRight: `${isMobile ? "10px" : "0"}` }}
          >
            {t.uppercaseTitle.map((title) => (
              <p
                className="therapistTitle"
                style={{
                  fontWeight: "700",
                  fontSize: `${isMobile ? "16px" : "32px"}`,
                  letterSpacing: `${isMobile ? "0.15em" : "0.15em"}`,
                  margin: "10px 0",
                }}
              >
                {title}
              </p>
            ))}
            {t.Text.map((tx) => (
              <p
                className="textItem"
                style={{
                  fontWeight: "400",
                  fontSize: `${isMobile ? "12px" : "16px"}`,
                  lineHeight: `${isMobile ? "15px" : "22px"}`,
                  margin: "10px 0",
                }}
              >
                {tx}
              </p>
            ))}
          </div>
          <div>
            <img
              src={t.photo}
              style={imageStyle}
              alt={`${t.Title[0]} headshot`}
            />
          </div>
        </div>
      </li>
    );
  });

  return (
    <>
      <div
        className="background aboutPage"
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Logo />
      </div>
      <div
        className="aboutPage therapists section"
        style={{
          backgoundColor: "#FFFFFF",
          fontWeight: "700",
          padding: `${padding}`,
          fontSize: "13px",
          letterSpacing: "1.5",
          textAlign: "left",
        }}
      >
        <ul
          className="therapists list"
          style={{ listStyle: "none", paddingLeft: "0" }}
        >
          {therapists}
        </ul>
        <div
          style={{
            fontSize: `${isMobile ? "15px" : "22px"}`,
            // textAlign: "center",
            fontWeight: "700",
            color: "#504D4D",
          }}
        >
          Many individuals struggle to find a therapist they are able to connect
          with. Our highly trained staff has a diverse set of experiences to
          meet most needs. Our goal is to find the right therapist for you.
        </div>
      </div>
    </>
  );
}

export default About;
