import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import useWindowSize from "../../../utilities/useWindowSize";
import MkTherapyHomeLogo2x from "../../../assets/MkTherapyHomeLogo@2x.png";
import AboutPageLogo2x from "../../../assets/AboutPageLogo@2x.png";
import ServicesPageLogo2x from "../../../assets/ServicesPageLogo@2x.png";
import ContactPageLogo2x from "../../../assets/ContactLogo@2x.png";

function Logo() {
  const [isMobile, setMobile] = useState(false);
  const [path, setPath] = useState(undefined);
  const { pathname } = useLocation();
  const windowSize = useWindowSize();

  useEffect(() => {
    setMobile(windowSize.width > 900 ? false : true);
  }, [windowSize]);

  useEffect(() => {
    setPath(pathname);
  }, [pathname]);

  let source;
  switch (path) {
    case "/home":
      source = MkTherapyHomeLogo2x;
      break;
    case "/about":
      source = AboutPageLogo2x;
      break;
    case "/services":
      source = ServicesPageLogo2x;
      break;
    case "/contact":
      source = ContactPageLogo2x;
      break;
    default:
      source = MkTherapyHomeLogo2x;
      break;
  }

  let imageClassName = "logo";
  if (isMobile) imageClassName += " mobile";

  const imageStyle = {
    margin: isMobile ? "70px auto" : "100px auto",
    height: "auto",
    width: isMobile ? "80vw" : "488px",
  };

  return (
    <img
      src={source}
      alt="MK Therapy Services Logo"
      className={imageClassName}
      style={imageStyle}
    />
  );
}

export default Logo;
