import { NavLink } from "react-router-dom";
import "./Modal.css";

const Modal = ({ modal }) => {
  if (!modal) return false;
  return (
    <div
      id="modalBackground"
      className="modal navLinks background"
      style={{
        display: "block",
        position: "fixed",
        zIndex: "1",
        top: "0",
        left: "0",
        width: "100%",
        height: "100vh",
        overflow: "auto",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        transition: "1.1s ease-out",
        backdropFilter: "blur(10px)",
      }}
    >
      <div
        id="modalContant"
        className="modal navLinks container"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <NavLink className="navLink modal" activeClassName="active" to="/home">
          HOME
        </NavLink>
        <NavLink className="navLink modal" activeClassName="active" to="/about">
          ABOUT
        </NavLink>
        <NavLink
          className="navLink modal"
          activeClassName="active"
          to="/services"
        >
          SERVICES
        </NavLink>
        <NavLink
          className="navLink modal"
          activeClassName="active"
          to="/contact"
        >
          CONTACT
        </NavLink>
      </div>
    </div>
  );
};

export default Modal;

/* 
<svg xmlns="http://www.w3.org/2000/svg" width="428" height="926" viewBox="0 0 428 926">
  <rect id="Rectangle_16" data-name="Rectangle 16" width="428" height="926" fill="#fff"/>
</svg>
 */
