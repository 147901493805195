import { useState, useEffect } from "react";
import useWindowSize from "../../utilities/useWindowSize";
import { ReactComponent as CallIcon } from "../../assets/call_32.svg";
import { ReactComponent as EmailIcon } from "../../assets/email_32.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/LinkedInIcon32.svg";

import "./Footer.css";

function Footer() {
  const [isMobile, setMobile] = useState(false);

  const windowSize = useWindowSize();

  useEffect(() => {
    setMobile(windowSize.width > 900 ? false : true);
  }, [windowSize]);

  if (isMobile) {
    return (
      <div className="Footer Mobile">
        <a
          href="mailto:marissa@mktherapyservices.com"
          className="contact value mobile"
        >
          <EmailIcon height="32" width="32" />
        </a>
        <a
          href="https://www.linkedin.com/in/marissa-kaufman-lcsw-r-casac-08483784/"
          target="_blank"
          rel="noreferrer"
          className="contact value mobile"
        >
          <LinkedInIcon height="32" width="32" />
        </a>
        <a href="tel:516-316-5808" className="contact value mobile">
          <CallIcon height="32" width="32" />
        </a>
      </div>
    );
  } else if (!isMobile) {
    return (
      <div className="Footer">
        <div className="paragraphText">
          <p className="paragraph">
            WE’D LOVE TO GET TO KNOW YOU, AND HOW WE CAN MEET YOUR NEEDS. YOU
            ARE WELCOME TO EMAIL OR CALL TO SCHEDULE A COMPLIMINTARY 20 MINUTE
            INTRODUCTORY CONSULTATION
          </p>
        </div>
        <div className="contact">
          <a href="tel:516-316-5808" className="value">
            516-316-5808
          </a>
        </div>
        <div className="contact">
          <a href="mailto:marissa@mktherapyservices.com" className="value">
            MARISSA@MKTHERAPYSERVICES.COM
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
