import Logo from "./PageComponents/Logo";
import useWindowSize from "../../utilities/useWindowSize";
import ContactPageBackground from "../../assets/ContactPageBackground.png";
import { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import "./Contact.css";
import Keys from "../../config/emailjskeys";
/* 
  Pictures are cut off on mobile for about page and contact pages
 */

function Contact() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  // const [errors, setErrors] = useState({
  //   firstname: false,
  //   lastname: false,
  //   email: false,
  //   subject: false,
  //   message: false,
  // });
  const [isMobile, setMobile] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    setMobile(windowSize.width > 900 ? false : true);
  }, [windowSize]);

  let background;
  let padding;
  if (isMobile) {
    background = ContactPageBackground;
    padding = "65px 25px";
  } else {
    background = ContactPageBackground;
    padding = "25px 50px";
  }

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setSubject("");
    setMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      firstname: firstName,
      lastname: lastName,
      email: emailAddress,
      subject: subject,
      message: message,
    };
    emailjs.send(Keys.serviceId, Keys.templateId, formData, Keys.userId);
    clearForm();
  };

  const inputStyle = {
    fontSize: `${isMobile ? "14px" : "20px"}`,
    fontWeight: "700",
    color: "#504D4D",
    border: "1px solid #707070",
    borderRadius: "3px",
    padding: "10px 2%",
    style: "text-transform:uppercase",
  };

  return (
    <>
      <div
        className="contactPage background"
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Logo />
      </div>
      <div
        className="contactForm container section"
        style={{
          backgoundColor: "#FFFFFF",
          fontWeight: "700",
          padding: `${padding}`,
          fontSize: "13px",
          letterSpacing: "1.5",
          textAlign: "left",
        }}
      >
        <form
          className="contactForm form"
          id="contactForm"
          onSubmit={(e, keys) => handleSubmit(e, keys)}
        >
          <h3
            className="contactForm header"
            style={{
              fontSize: `${isMobile ? "22px" : "32px"}`,
              fontWeight: "700",
              textAlign: "left",
              marginBottom: "10px",
              color: "#504D4D",
            }}
          >
            CONTACT
          </h3>
          <p
            className="contactPage subHeader"
            style={{
              marginBottom: `${isMobile ? "19px" : "68px"}`,
              color: "#504D4D",
              lineHeight: `${isMobile ? "15px" : "18px"}`,
              fontSize: `${isMobile ? "12px" : "16px"}`,
              fontWeight: "400",
            }}
          >
            If you are interested in booking a first appointment or learning
            more about our practice and what we offer, please fill out the form
            below and we will get back to you at our earliest possible
            convenience.
          </p>
          <div
            className="formGroup"
            style={{ display: "flex", flexDirection: "row", width: "100%" }}
          >
            <label
              className="label name first"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                fontSize: `${isMobile ? "14px" : "20px"}`,
                color: "#504D4D",
                fontWeight: "700",
              }}
            >
              NAME
              <div
                className="inner formGroup"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <input
                  type="text"
                  className="form input name first"
                  placeholder="FIRST NAME"
                  style={inputStyle}
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                  value={firstName}
                ></input>

                <input
                  type="text"
                  className="form input name first"
                  placeholder="LAST NAME"
                  style={inputStyle}
                  onChange={(e) => setLastName(e.currentTarget.value)}
                  value={lastName}
                ></input>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              ></div>
            </label>
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: `${isMobile ? "10px" : "14px"}`,
            }}
          >
            <label
              className="label email"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                fontSize: `${isMobile ? "14px" : "20px"}`,
                color: "#504D4D",
                fontWeight: "700",
              }}
            >
              EMAIL ADDRESS
              <div
                className="inner formGroup"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <input
                  type="text"
                  className="form input email"
                  placeholder="EMAIL ADDRESS"
                  style={{ ...inputStyle, width: "100%" }}
                  onChange={(e) => setEmailAddress(e.currentTarget.value)}
                  value={emailAddress}
                ></input>
              </div>
            </label>
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: `${isMobile ? "10px" : "14px"}`,
            }}
          >
            <label
              className="label subject"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                fontSize: `${isMobile ? "14px" : "20px"}`,
                color: "#504D4D",
                fontWeight: "700",
              }}
            >
              SUBJECT
              <div
                className="inner formGroup"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <input
                  type="text"
                  className="form input subject"
                  placeholder="SUBJECT"
                  style={{ ...inputStyle, width: "100%" }}
                  onChange={(e) => setSubject(e.currentTarget.value)}
                  value={subject}
                ></input>
              </div>
            </label>
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "23%",
              marginTop: `${isMobile ? "10px" : "14px"}`,
            }}
          >
            <label
              className="label message"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                fontSize: `${isMobile ? "14px" : "20px"}`,
                color: "#504D4D",
                fontWeight: "700",
              }}
            >
              MESSAGE
              <div
                className="inner formGroup"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <textarea
                  type="textarea"
                  className="form input message"
                  placeholder="MESSAGE"
                  style={{
                    ...inputStyle,
                    width: "100%",
                    height: `${isMobile ? "140px" : "250px"}`,
                    resize: "none",
                    textJustify: "initial",
                  }}
                  onChange={(e) => setMessage(e.currentTarget.value)}
                  value={message}
                ></textarea>
              </div>
            </label>
          </div>
          <button
            className="form submit button"
            style={{
              height: `${isMobile ? "40px" : "50px"}`,
              width: "28%",
              border: "1px solid #D4D2D2",
              backgroundColor: "#D4D2D2",
              borderRadius: "3px",
              marginTop: `${isMobile ? "20px" : "30px"}`,
              fontSize: `${isMobile ? "17px" : "23px"}`,
              fontWeight: "700",
              color: "#FFFFFF",
            }}
          >
            SUBMIT
          </button>
        </form>
      </div>
    </>
  );
}

export default Contact;
