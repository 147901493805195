import Logo from "./PageComponents/Logo";
import useWindowSize from "../../utilities/useWindowSize";
import { useState, useEffect } from "react";
import HomePageDesktopBackground from "../../assets/HomePageDesktopBackground.png";
import "./Home.css";

function Home() {
  const [isMobile, setMobile] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    setMobile(windowSize.width > 900 ? false : true);
  }, [windowSize]);

  let background;
  let padding;
  if (isMobile) {
    background = HomePageDesktopBackground;
    padding = "65px 25px";
  } else {
    background = HomePageDesktopBackground;
    padding = "25px 50px";
  }

  let headerStyle = { margin: "15px 0", fontWeight: "700" };
  if (isMobile) {
    headerStyle.fontSize = "13px";
    headerStyle.lineHeight = "13px";
  } else {
    headerStyle.fontSize = "22px";
    headerStyle.lineHeight = "26px";
  }

  let paragraphStyle = {
    fontWeight: "400",
    fontSize: `${isMobile ? "12px" : "16px"}`,
    lineHeight: `${isMobile ? "15px" : "22px"}`,
    marginBottom: "15px",
  };

  return (
    <>
      <div
        className="background homePage"
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Logo />
      </div>
      <div
        className="homePage paragraph"
        style={{
          backgoundColor: "#FFFFFF",
          fontWeight: "700",
          padding: `${padding}`,
          fontSize: `${isMobile ? "12px" : "16px"}`,
          letterSpacing: "1.5",
          textAlign: "left",
        }}
      >
        <p className="homePage header" style={headerStyle}>
          NEW YORK CITY-BASED LICENSED SOCIAL WORKERS AND CREDENTIALED
          ALCOHOLISM AND SUBSTANCE ABUSE COUNSELORS
        </p>
        <p className="homePage paragraphText" style={paragraphStyle}>
          {"MK Therapy Services is a psychotherapy practice that works closely with individuals to improve their emotional well-being, enhance their ability to engage in interpersonal relationships, and to empower them with useful tools to live a more fulfilling life.".toUpperCase()}
        </p>
        <p className="homePage paragraphText" style={paragraphStyle}>
          {"We offer therapy services in New York City and virtually through Telehealth. Our therapists provide psychotherapy to individuals of all ages with a strong focus on young adults. We are here to help!".toUpperCase()}
          {/* we offer in person therapy services  */}
        </p>
      </div>
    </>
  );
}

export default Home;
