import "./Navigation.css";
import { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import MkTherapyHeaderLogo from "../../assets/MkTherapyServicesHeaderLogo@3x.png";
import { ReactComponent as HamburgerMenu } from "../../assets/hamburger.svg";
import useWindowSize from "../../utilities/useWindowSize";
import Modal from "./Modal";

const Navigation = () => {
  const [isMobile, setMobile] = useState(false);
  const [modal, setModal] = useState(false);

  const windowSize = useWindowSize();

  useEffect(() => {
    setMobile(windowSize.width > 900 ? false : true);
  }, [windowSize]);

  let modalContainer;
  if (!modal) {
    modalContainer = null;
  } else {
    return (
      <div onClick={(e) => setModal(false)}>
        <Modal modal={modal} />
      </div>
    );
  }

  let menu;
  if (isMobile) {
    menu = (
      <HamburgerMenu
        style={{ cursor: "pointer" }}
        onClick={(e) => setModal(true)}
      />
    );
  } else {
    menu = (
      <nav className="navbar navLinks flexContainer">
        <NavLink className="navLink" activeClassName="active" to="/home">
          HOME
        </NavLink>
        <NavLink className="navLink" activeClassName="active" to="/about">
          ABOUT
        </NavLink>
        <NavLink className="navLink" activeClassName="active" to="/services">
          SERVICES
        </NavLink>
        <NavLink className="navLink" activeClassName="active" to="/contact">
          CONTACT
        </NavLink>
      </nav>
    );
  }

  return (
    <>
      <div className="navbar">
        <Link to="/home">
          <img
            src={MkTherapyHeaderLogo}
            alt="MK Therapy Services Logo"
            className="MkTherapyServicesLogo"
            width="auto"
            height="58"
          />
        </Link>
        {menu}
        {modalContainer}
      </div>
    </>
  );
};

export default Navigation;
