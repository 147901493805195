// import devKeys from "./keys";

const Keys = () => {
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // return devKeys;
  // } else {
  return {
    serviceId: process.env.REACT_APP_SERVICE_ID,
    templateId: process.env.REACT_APP_TEMPLATE_ID,
    userId: process.env.REACT_APP_USER_ID,
  };
  // }
};

export default Keys();
