import StevenFloydHS from "../assets/StevenFloyd.jpeg";
import MarissaKaufmanHS from "../assets/MarissaKaufman.png";
import FrancesaPaparoneHS from "../assets/FrancesaPaparone.jpg";

const Therapists = [
  {
    Title: ["Marissa Kaufman", "LCSW-R, CASAC"],
    uppercaseTitle: ["MARISSA KAUFMAN", "LCSW-R, CASAC"],
    photo: MarissaKaufmanHS,
    Text: [
      "Marissa Kaufman (LCSW-R, CASAC) is the founder and CEO of MK Therapy Services. She is a Licensed Clinical Social Worker and Credentialed Alcoholism and Substance Abuse Counselor with more than a decade of experience and training in the treatment of mental health and substance abuse.",
      "Marissa uses an individualized approach in her practice, including various psychodynamic treatment modalities, to create a therapeutic environment with the best interests of the individual client in mind.",
      "Prior to starting her private practice nearly a decade ago, Marissa worked at the New York Center for Living, where she facilitated an intensive outpatient treatment program for adolescents, young adults and families. Prior to that, she trained and worked at Bridge Back to Life, where her passion for working with young adults began.",
      "In addition, Marissa has extensive training in psychodrama and spent 7 years training with Tian Dayton.",
      "Marissa strives to meet individuals where they are, and help them get where they want to be. Her treatment specialties include substance abuse, anxiety, depression, codependency, adult children of alcoholics, trauma, fertility issues and supervision.",
    ],
  },
  {
    Title: ["Francesca Paparone", "LMSW"],
    uppercaseTitle: ["FRANCESCA PAPARONE", "LMSW, CASAC-T"],
    photo: FrancesaPaparoneHS,
    Text: [
      "Francesca Paparone is a Licensed Master Social Worker and Credentialed Alcohol and Substance abuse counselor trainee. Francesca received her masters degree from Fordham University in 2019.",
      "Her experience includes working with individuals who are experiencing substance abuse and mental health concerns in both residential and outpatient settings as well as supporting their loved ones. Francesca also currently holds the program director position at a New York City transitional living facility.",
      "Francesca has an eclectic style, utilizing solution focused and motivational enhancement therapies. Approaching each therapeutic relationship from a non-judge mental and supportive place, Francesca encourages clients to take ownership over their own stories. Francesca’s holistic style of therapy allows clients to better understand their own behaviors and patterns and to take the first steps on their journey of healing.",
    ],
  },
  {
    Title: ["Steven Floyd", "LMSW, CASAC"],
    uppercaseTitle: ["STEVEN FLOYD", "LMSW, CASAC"],
    photo: StevenFloydHS,
    Text: [
      "Steven Floyd is a Licensed Social Worker and Credentialed Alcoholism and Substance Abuse Counselor. He has worked extensively with adults and families in dual diagnosis intensive outpatient treatment. He is a psychodynamic therapist with an open, warm and collaborative style and a passion for Acceptance and Commitment Therapy (ACT), Couples Therapy and Group Psychotherapy.",
      "Steven has more than a decade of experience in 12-step recovery, Buddhist recovery, and meditation practices which inform his work with clients.",
      "He received his Master's in Social Work from Fordham University and is an Eastern Group Psychotherapy Society member. Steven is a proud member of the LGBTQIA community and continuously works to have a practice that is foundationally anti-racist and anti-oppressive.",
      "Steven is an excellent resource for clients who want to grow in the areas of mental health, recovery, relationships and identity.",
    ],
  },
];

export default Therapists;
