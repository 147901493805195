const ServicesData = [
  {
    title: "INDIVIDUAL THERAPY",
    bullets: [
      "Substance Abuse",
      "Anxiety and Panic",
      "Depression",
      "Relationship Issues and Codependency",
      "Family Dynamics",
      "Trauma",
      "Young Adult Issues",
      "Life Transitions",
      "Work Relationships & Career - Development",
      "Fertility",
    ],
  },
  {
    title: "GROUP THERAPY",
    bullets: ["Family Counseling", "Couples Counseling", "Men's Group"],
  },
  {
    title: "EVALUATIONS",
    bullets: ["OASAS Assessments and Evaluations"],
  },
  {
    title: "SUPERVISION",
  },
];

export default ServicesData;
