import Logo from "./PageComponents/Logo";
import { useState, useEffect } from "react";
import useWindowSize from "../../utilities/useWindowSize";
import ServicesPageBackground from "../../assets/ServicesPageBackground.png";
import "./Services.css";
import ServicesData from "../../data/Services";
import WhateverItTakes from "../../assets/WhateverItTakes2x.png";

function Services() {
  const [isMobile, setMobile] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    setMobile(windowSize.width > 900 ? false : true);
  }, [windowSize]);

  let background;
  let padding;
  let imageStyle;
  if (isMobile) {
    background = ServicesPageBackground;
    padding = "65px 25px";
    imageStyle = { height: "100px", width: "100%", objectFit: "cover" };
  } else {
    background = ServicesPageBackground;
    padding = "25px 50px";
    imageStyle = {
      height: "200px",
      width: "100%",
      objectFit: "cover",
      objectPosition: "100% 43%",
    };
  }

  const services = ServicesData.map((s) => {
    return (
      <li
        className="services listItem"
        style={{
          listStyle: "none",
          fontSize: `${isMobile ? "20px" : "32px"}`,
          fontWeight: "700",
          letterSpacing: "0.15em",
          textAlign: "left",
        }}
      >
        <h1 className="services sectionHeader" style={{ margin: "10px 0" }}>
          {s.title}
        </h1>
        <ul className="services bulletItem">
          {!s.bullets
            ? null
            : s.bullets.map((b) => (
                <li
                  className="bullet item"
                  style={{
                    listStyle: "none",
                    fontSize: `${isMobile ? "12px" : "22px"}`,
                    lineHeight: `${isMobile ? "15px" : "26px"}`,
                    fontWeight: "400",
                    margin: "5px 0",
                    marginLeft: "15px",
                    letterSpacing: "0.05em",
                  }}
                >
                  {b}
                </li>
              ))}
        </ul>
      </li>
    );
  });

  return (
    <>
      <div
        className="servicesPage background"
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Logo />
      </div>
      <div
        className="servicesPage services section"
        style={{
          backgoundColor: "#FFFFFF",
          fontWeight: "700",
          padding: `${padding}`,
          fontSize: "13px",
          letterSpacing: "1.5",
          textAlign: "left",
        }}
      >
        {services}
        <div
          style={{
            color: "#504D4D",
            fontSize: `${isMobile ? "12px" : "16px"}`,
            lineHeight: `${isMobile ? "15px" : "18px"}`,
            marginTop: "50px",
          }}
        >
          {"We are sensitive to the uniqueness of each clients situation. We adapt our consultations and treatment options appropriately to accommodate individual needs and concerns.".toUpperCase()}
        </div>
      </div>
      <img
        src={WhateverItTakes}
        style={imageStyle}
        alt="Inspirational Quote 'whatever it takes' "
      />
    </>
  );
}

export default Services;
